export const SET_TOP_CREATORS = "SET_TOP_CREATORS";
export const SET_SUGGESTED_USERS = "SET_SUGGESTED_USERS";

export const SetTopCreatorsAction = (payload) => {
  return { type: SET_TOP_CREATORS, payload };
};

export const SetSuggestedUsersAction = (payload) => {
  return { type: SET_SUGGESTED_USERS, payload };
};
