import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const updateNotificationCount = (payload) => {
  return serviceClient.post("v1/notifications/update-status-all", payload);
};

export const fetchNotifications = (payload, config) => {
  const searchParams = new URLSearchParams();
  searchParams.set("type", payload.type ?? "all");
  searchParams.set("page", payload.page ?? "1");
  searchParams.set("size", payload.size ?? 10);

  return serviceClient.get(
    "v1/notifications?" + searchParams.toString(),
    config
  );
};

export const storeFcmToken = (payload) => {
  return serviceClient.post("v1/notification/users/fcm-token", payload);
};

export const checkFcmToken = () => {
  return serviceClient.get("v1/notification/users/check-fcm-token");
};

export const fetchNotificationPreferences = () => {
  return serviceClient.get("v1/user/notification-preferences");
};

export const updateNotificationPreferences = (payload, config) => {
  return serviceClient.post(
    "v1/user/notification-preferences",
    payload,
    config
  );
};

export const fetchUnreadCount = () => {
  return serviceClient.get("v1/notification/unread-count");
};
