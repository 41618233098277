import { combineReducers } from "redux";
import customizer from "./customizer/customizer.js";
import app from "./app/appReducer";
import messaging from "./messaging/messagingReducer.js";
import notifications from "./notifications/notificationsReducer.js";
import live from "./liveReducer/liveReducer.js";
import mediaDevice from "./mediaDevice/mediaDeviceReducer.js";
import modal from "./modal/modalReducer.js";
import posts from "../reducers/posts/postsReducer.js";
import users from "./users/usersReducer.js";

const rootReducer = combineReducers({
  customizer: customizer,
  app: app,
  messaging: messaging,
  notifications: notifications,
  live: live,
  mediaDevice: mediaDevice,
  modal: modal,
  posts: posts,
  users: users,
});

export default rootReducer;
