export const OPEN_LIGHTBOX = "OPEN_LIGHTBOX";
export const CLOSE_LIGHTBOX = "CLOSE_LIGHTBOX";
export const OPEN_VERIFICATION_MODAL = "OPEN_VERIFICATION_MODAL";
export const CLOSE_VERIFICATION_MODAL = "CLOSE_VERIFICATION_MODAL";

export const OpenLightboxAction = (payload) => {
  /*
    Payload type 
    {
      slides: {src: string}[]
      index?: number
    }
  */
  return {
    type: OPEN_LIGHTBOX,
    payload,
  };
};

export const CloseLightboxAction = () => {
  return {
    type: CLOSE_LIGHTBOX,
  };
};

export const OpenVerificationModalAction = () => {
  return {
    type: OPEN_VERIFICATION_MODAL,
  };
};

export const CloseVerificationModalAction = () => {
  return {
    type: CLOSE_VERIFICATION_MODAL,
  };
};
