import { fetchUploadURL } from "../services/uploadService";

export const getUploadURL = async () => {
  try {
    const response = await fetchUploadURL();
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log("ERR_FETCHING_UPLOAD_URL");
  }
};
