import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const getAuthenticatedMuxUrl = () => {
  return serviceClient.post("v1/mux/url");
};

export const fetchLiveUsers = () => {
  return serviceClient.get("v1/mux/live-stream/active-users");
};

export const toggleLiveStreamHeartbeat = (payload) => {
  return serviceClient.post("v1/mux/live-stream/toggle-heartbeat", payload);
};

export const updateMuxAssets = () => {
  return serviceClient.post("v1/mux/assets/update");
};

export const fetchSignedMuxJwt = (payload) => {
  return serviceClient.post("v1/mux/sign-in-keys", payload);
};

export const startSpaceBroadcast = (payload) => {
  return serviceClient.post("v1/mux/broadcast/start", payload);
};

export const stopSpaceBroadcast = (payload) => {
  return serviceClient.post("v1/mux/broadcast/stop", payload);
};

export const fetchLiveStreamDetails = (payload) => {
  return serviceClient.post("v1/mux/live-streamer/details", payload);
};

export const updateViewCount = (payload) => {
  return serviceClient.post("v1/mux/live-stream/views", payload);
};

export const tipLiveStream = (payload) => {
  return serviceClient.post("v1/mux/live-stream/tip-amount", payload);
};

export const uploadToMuxByUrl = (payload) => {
  return serviceClient.post("v1/mux/send-video-by-url", payload);
};
