import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { migrations } from "./storeMigrations";

const persistConfig = {
  key: "root",
  storage,
  version: 13,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ["messaging", "notifications", "live", "modal", "posts", "users"],
};
const middlewares = [thunk, createDebounce()];
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { store, persistor };
