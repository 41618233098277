import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const createPost = (payload, config) => {
  return serviceClient.post("v1/post", payload, config);
};

export const fetchUserPosts = (payload) => {
  let url = `v1/post/users/${payload.userId}`;

  url += `?page=${payload.page ?? 1}`;
  url += `&size=${payload.size ?? 10}`;
  if (payload.status) {
    url += `&status=${payload.status}`;
  }
  if (payload.type) {
    url += `&type=${payload.type.join(",")}`;
  }
  if (payload.archive) {
    url += `&archive=true`;
  }

  return serviceClient.get(url);
};

export const fetchPosts = (payload) => {
  let url = "v1/post/users";
  url += `?page=${payload.page ?? 1}`;
  url += `&size=${payload.size ?? 10}`;
  if (payload.status) {
    url += `&status=${payload.status}`;
  }
  if (payload.type) {
    url += `&type=${payload.type.join(",")}`;
  }

  return serviceClient.get(url);
};

export const fetchPublicPost = (payload) => {
  return serviceClient.get(`v1/feature-fan-posts/${payload.id}`);
};

export const fetchPublicPosts = (payload) => {
  return serviceClient.get(
    `v1/users/profile/posts?user_id=${payload.id}&no_of_records=${payload.count}`
  );
};

export const fetchPlatformPosts = (payload) => {
  return serviceClient.get(`v1/feature-fan-posts?page_no=${payload.page}`);
};

export const fetchPost = (payload) => {
  return serviceClient.post("v1/post/url", payload);
};

export const updatePost = (payload, urlParam) => {
  return serviceClient.post(`v1/post/update/${urlParam.id}`, payload);
};

export const deletePost = (postId) => {
  return serviceClient.delete(`v1/post/${postId}`);
};

export const reportPost = (payload, urlParam) => {
  return serviceClient.post(`v1/post/${urlParam.id}/report`, payload);
};

export const buyPost = (payload) => {
  return serviceClient.post("v1/post/unlocked", payload);
};

export const archivePost = (payload) => {
  return serviceClient.post("v1/post/archived", payload);
};

export const showSensitivePost = (payload) => {
  return serviceClient.post("v1/post/sensitive/content/show", payload);
};

export const appealPost = (payload, urlParam) => {
  return serviceClient.post(`v1/post/${urlParam.id}/appeal`, payload);
};

export const addComment = (payload) => {
  return serviceClient.post("v1/post/comment", payload);
};

export const deleteComment = (payload) => {
  return serviceClient.delete(`v1/post/comment/${payload.id}`);
};

export const addSubComment = (payload) => {
  return serviceClient.post("v1/post/sub-comment", payload);
};

export const deleteSubComment = (payload) => {
  return serviceClient.delete(`v1/post/sub-comment/${payload.id}`);
};

export const likePost = (payload, config) => {
  return serviceClient.post("v1/post/like", payload, config);
};

export const likeComment = (payload, config) => {
  return serviceClient.post("v1/comments/like", payload, config);
};

export const fetchReleaseForm = () => {
  return serviceClient.get("v1/post/release-form-doc");
};

export const fetchCoPerformerReleaseForm = (payload) => {
  return serviceClient.post("v1/post/release-form-with-co-uploader", payload);
};

export const signReleaseForm = (payload) => {
  return serviceClient.post("v1/post/accept-agreement", payload);
};

export const userFuzzySearch = (payload, config) => {
  let url = "v1/post/tagged-users/search";

  url += `?search=${payload.search ?? ""}`;
  url += `&isModel=${payload.isModel}`;

  return serviceClient.get(url, config);
};

export const sendSignReminder = (payload) => {
  return serviceClient.post("v1/post/send-reminder", payload);
};

export const fetchComments = (payload) => {
  let url = `v1/comments/get/${payload.postId}`;

  url += `?page=${payload.page ?? 1}`;
  url += `&size=${payload.size ?? 5}`;

  return serviceClient.get(url);
};

export const fetchReplies = (payload) => {
  let url = `v1/comments/sub-comment/${payload.commentId}`;

  url += `?page=${payload.page ?? 1}`;
  url += `&size=${payload.size ?? 3}`;

  return serviceClient.get(url);
};

export const fetchLikes = (payload) => {
  let url = `v1/post/likes-list/${payload.postId}`;

  url += `?page=${payload.page ?? 1}`;
  url += `&size=${payload.size ?? 10}`;

  return serviceClient.get(url);
};

export const fetchComment = (payload) => {
  let url = `v1/comments/fetch/${payload.commentId}`;

  if (payload.replyId) {
    url += `?subcommentID=${payload.replyId}`;
  }

  return serviceClient.get(url);
};

export const fetchPostImages = (payload) => {
  let url = `v1/post/get-uploaded-photos/${payload.userId}`;

  url += `?page=${payload.page ?? 1}`;
  url += `&size=${payload.size ?? 10}`;

  return serviceClient.get(url);
};
