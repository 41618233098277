import {
  SET_MESSAGE_COUNT,
  INCREASE_UNREAD_MESSAGE_COUNT,
} from "../../actions/messaging/messagingActions";

export const messagingInitialState = {
  newMessageCount: 0,
};

const messaging = (state = messagingInitialState, action) => {
  switch (action.type) {
    case SET_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: action.value,
      };
    case INCREASE_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: state.newMessageCount + 1,
      };
    default:
      return state;
  }
};
export default messaging;
