export default function notificationRedirection(notification, history) {
  switch (notification.notification_type) {
    case "Rejected":
      if (notification.data) {
        history.push(`/post/${notification.data}#${Date.now()}`);
      } else {
        history.push(`/settings/#/post-logs/rejected`);
      }
      break;
    case "Live":
    case "live":
      history.push(`/stream/${notification.user.username}`);
      break;
    case "subscription":
      history.push("/settings/#/subscribers");
      break;
    case "follow":
    case "tip":
    case "unsubscribe":
    case "subscriptionCreate":
    case "subcription":
      history.push(`/${notification.user.username}`);
      break;
    case "Approved":
    case "like":
    case "Release Form":
    case "tag":
    case "paidPost":
    case "unlockedPost":
    case "comment":
      history.push(`/post/${notification.data}#${Date.now()}`);
      break;
    case "chat":
      history.push(`/chat#${notification.user.id}`);
      break;
    case "ticketUpdate":
      history.push("/settings/#/help-support");
      break;
    case "refundStatusUpdate":
    case "refundRequest":
      history.push("/settings/#/wallet");
      break;
    case "shop create":
    case "buyShop":
    case "vodApproved":
      history.push(`/shopsingle/${notification.data.split("/").at(-1)}`);
      break;
    case "vodRejected":
      history.push("/settings/#/vod-management");
      break;
    case "newRegister":
    case "forgotpassword":
    case "passwordChanged":
    case "becomeModel":
      history.push("/");
      break;
    case "withdrawRequest":
    case "withdrawalStatusUpdate":
    case "withdrawStatusUpdate":
      history.push("/settings/#/earnings/withdrawal-request");
      break;
    case "verificationRequest":
    case "Verified":
    case "rejectFan":
      history.push("/settings/#/verification");
      break;
    case "rejectModel":
      history.push("/become-model");
      break;
    case "subscriptionUpdate":
      history.push(`/${notification.user.username}`);
      break;
    case "releaseformSigned":
      history.push(`/post/${notification.data}#${Date.now()}`);
      break;
    case "From Feature Fan Authority":
      break;
    case "reportPost":
      if (notification.data.includes("-")) {
        history.push(`/post/${notification.data.split("-")[0]}#${Date.now()}`);
      } else {
        history.push(`/post/${notification.data}#${Date.now()}`);
      }
      break;

    default:
      history.push("/");
  }
}
