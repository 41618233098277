import {
  SET_NOTIFICATION_COUNT,
  INCREASE_NOTIFICATION_COUNT,
} from "../../actions/notifications/notificationsActions";

export const notificationsInitialState = {
  notificationCount: 0,
};

const notifications = (state = notificationsInitialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.value,
      };
    case INCREASE_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
      };
    default:
      return state;
  }
};

export default notifications;
