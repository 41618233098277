export const SET_FEED = "SET_FEED";
export const SET_PREMIUM = "SET_PREMIUM";
export const SHOULD_REFRESH_POSTS = "SHOULD_REFRESH_POSTS";
export const REMOVE_ALL_CACHED_POSTS = "REMOVE_ALL_CACHED_POSTS";

export const SetFeedPosts = (payload) => {
  return { type: SET_FEED, payload };
};

export const SetPremiumPosts = (payload) => {
  return { type: SET_PREMIUM, payload };
};

export const ShouldRefreshPostsAction = (payload) => {
  return { type: SHOULD_REFRESH_POSTS, payload };
};

export const RemoveAllCachedPostsAction = (payload) => {
  return { type: REMOVE_ALL_CACHED_POSTS, payload };
};
