import React from "react";
import {
  Home,
  User,
  Video,
  MessageSquare,
  Search,
  Folder,
  ShoppingBag,
  UserCheck,
  Radio,
} from "react-feather";
export const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    navLink: "/Home",
  },
  {
    id: "page2",
    title: "Profile",
    type: "item",
    icon: <User size={20} />,
    navLink: "/profile",
  },
  // {
  //   id: "shop",
  //   title: "Shop",
  //   type: "item",
  //   icon: <ShoppingBag size={20} />,
  //   navLink: "/shop",
  // },
  {
    id: "live",
    title: "Go Live",
    type: "item",
    icon: <Radio size={20} />,
    navLink: "/streaming",
  },
  {
    id: "VOD",
    title: "VOD",
    type: "item",
    icon: <Video size={20} />,
    navLink: "/VOD",
  },
  {
    id: "Messaging",
    title: "Messaging",
    type: "item",
    icon: <MessageSquare size={20} />,
    navLink: "/chat",
    badge: "warning",
  },
  {
    id: "Search",
    title: "Search",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Search",
  },
  {
    id: "Creators",
    title: "Top Creators",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Creators",
  },
  {
    id: "MyData",
    title: "My Items",
    type: "item",
    icon: <Folder size={20} />,
    navLink: "/MyItems",
  },
  {
    id: "Merch",
    title: "Merch Store",
    type: "external-link",
    icon: <ShoppingBag size={20} />,
    navLink: "http://featurefansmerch.com/",
    newTab: true,
  },
];

export const navigationConfigFan = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    navLink: "/Home",
  },
  {
    id: "page2",
    title: "Profile",
    type: "item",
    icon: <User size={20} />,
    navLink: "/profile",
  },
  // {
  //   id: "shop",
  //   title: "Shop",
  //   type: "item",
  //   icon: <ShoppingBag size={20} />,
  //   navLink: "/shop",
  // },
  // {
  //   id: "live",
  //   title: "Live",
  //   type: "item",
  //   icon: <Radio size={20} />,
  //   navLink: "/streaming",
  // },
  {
    id: "VOD",
    title: "VOD",
    type: "item",
    icon: <Video size={20} />,
    navLink: "/VOD",
  },
  {
    id: "Messaging",
    title: "Messaging",
    type: "item",
    icon: <MessageSquare size={20} />,
    navLink: "/chat",
    badge: "warning",
  },
  {
    id: "Search",
    title: "Search",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Search",
  },
  {
    id: "Creators",
    title: "Top Creators",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Creators",
  },
  {
    id: "MyData",
    title: "My Items",
    type: "item",
    icon: <Folder size={20} />,
    navLink: "/MyItems",
  },
  {
    id: "becomeAModel",
    title: "Become A Creator",
    type: "item",
    icon: <UserCheck size={20} />,
    navLink: "/become-model",
  },
  {
    id: "Merch",
    title: "Merch Store",
    type: "external-link",
    icon: <ShoppingBag size={20} />,
    navLink: "http://featurefansmerch.com/",
    newTab: true,
  },
];

export const navigationConfigModerator = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    navLink: "/Home",
  },
  {
    id: "page2",
    title: "Profile",
    type: "item",
    icon: <User size={20} />,
    navLink: "/Profile",
  },
  {
    id: "Messaging",
    title: "Messaging",
    type: "item",
    icon: <MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "Search",
    title: "Search",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Search",
  },
  {
    id: "Creators",
    title: "Top Creators",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Creators",
  },
  {
    id: "MyData",
    title: "My Items",
    type: "item",
    icon: <Folder size={20} />,
    navLink: "/MyItems",
  },
  {
    id: "Merch",
    title: "Merch Store",
    type: "external-link",
    icon: <ShoppingBag size={20} />,
    navLink: "http://featurefansmerch.com/",
    newTab: true,
  },
];
