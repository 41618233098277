import {
  SET_TOP_CREATORS,
  SET_SUGGESTED_USERS,
} from "../../actions/users/usersActions";

export const usersInitialState = {
  topCreators: null,
  suggestedUsers: null,
};

const users = (state = usersInitialState, action) => {
  switch (action.type) {
    case SET_TOP_CREATORS:
      return {
        ...state,
        topCreators: action.payload,
      };
    case SET_SUGGESTED_USERS:
      return {
        ...state,
        suggestedUsers: action.payload,
      };

    default:
      return state;
  }
};

export default users;
