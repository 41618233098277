import React from "react";
class CheckBoxesVuexy extends React.Component {
  render() {
    return (
      <div
        className={`vx-checkbox-con vx-checkbox-${this.props.color} ${
          this.props.parentClassName ?? ""
        }`}
        style={this.props.style}
      >
        <input
          type="checkbox"
          defaultChecked={this.props.defaultChecked}
          checked={this.props.checked}
          value={this.props.value}
          name={this.props.name}
          className={this.props.className}
          disabled={this.props.disabled}
          onClick={this.props.onClick ? this.props.onClick : null}
          onChange={this.props.onChange ? this.props.onChange : null}
        />
        <span
          className={`vx-checkbox vx-checkbox-${
            this.props.size ? this.props.size : "md"
          } ${this.props.iconClassName}`}
        >
          <span className="vx-checkbox--check">{this.props.icon}</span>
        </span>
        <span>{this.props.label}</span>
        {this.props.children}
      </div>
    );
  }
}

export default CheckBoxesVuexy;
