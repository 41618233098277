import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const fetchCmsContent = () => {
  return serviceClient.get("v1/cms-content");
};

export const fetchFlaggingKeywords = () => {
  return serviceClient.get("v1/flagging-keywords");
};
