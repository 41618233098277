import {
  OPEN_LIGHTBOX,
  CLOSE_LIGHTBOX,
  OPEN_VERIFICATION_MODAL,
  CLOSE_VERIFICATION_MODAL,
} from "../../actions/modal/modalActions";

export const modalInitialState = {
  isLightboxOpen: false,
  lightboxData: undefined,
  isVerificationModalOpen: false,
};

const modalReducer = (state = modalInitialState, action) => {
  switch (action.type) {
    case OPEN_LIGHTBOX:
      return {
        ...state,
        isLightboxOpen: true,
        lightboxData: action.payload,
      };
    case CLOSE_LIGHTBOX:
      return {
        ...state,
        isLightboxOpen: false,
        lightboxData: undefined,
      };
    case OPEN_VERIFICATION_MODAL:
      return {
        ...state,
        isVerificationModalOpen: true,
      };
    case CLOSE_VERIFICATION_MODAL:
      return {
        ...state,
        isVerificationModalOpen: false,
      };

    default:
      return state;
  }
};
export default modalReducer;
