import {
  SET_FEED,
  SET_PREMIUM,
  SHOULD_REFRESH_POSTS,
  REMOVE_ALL_CACHED_POSTS,
} from "../../actions/posts/postsActions";

export const postsInitialState = {
  feed: null,
  premium: null,
  shouldRefreshPosts: false,
};

const posts = (state = postsInitialState, action) => {
  switch (action.type) {
    case SET_FEED:
      return {
        ...state,
        feed: action.payload,
      };
    case SET_PREMIUM:
      return {
        ...state,
        premium: action.payload,
      };
    case SHOULD_REFRESH_POSTS:
      return { ...state, shouldRefreshPosts: action.payload };
    case REMOVE_ALL_CACHED_POSTS:
      return { ...state, feed: null, premium: null };
    default:
      return state;
  }
};

export default posts;
