import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge,
  Dropdown,
  Spinner,
  Button,
} from "reactstrap";
import { Bell } from "react-feather";
import moment from "moment";
import { SetNotificationsAsReadAction } from "../../../redux/actions/notifications/notificationsActions";
import { bellNotificationTypeToNameMap } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import notificationRedirection from "../../../utils/notificationRedirection";
import { Virtuoso } from "react-virtuoso";
import { fetchNotifications } from "../../../services/notificationService";
import { toast } from "react-toastify";
import axios from "axios";

const SIGNAL_CANCEL_MESSAGE = "signal_cancelled";

function NotificationsDropdown({
  theme,
  notificationCount,
  setNotificationsAsRead,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [didError, setDidError] = useState(false);
  const [page, setPage] = useState(1);

  const history = useHistory();
  const lastPage = useRef(null);
  const signalRef = useRef(null);

  const getNotifications = async () => {
    try {
      setIsLoading(true);
      const payload = { page, type: "all" };
      signalRef.current = axios.CancelToken.source();
      const response = await fetchNotifications(payload, {
        cancelToken: signalRef.current.token,
      });

      if (response.data.statusCode === 200) {
        setNotifications((prevNotifications) =>
          prevNotifications
            ? [...prevNotifications, ...response.data.data.data]
            : response.data.data.data
        );

        setDidError(false);
        lastPage.current = response.data.data.last_page;
      } else {
        setDidError(true);
        toast.error("Something went wrong");
      }
    } catch (err) {
      if (!!err.message && err.message !== SIGNAL_CANCEL_MESSAGE) {
        console.log("ERR_FETCHING_POSTS: ", err);
        setDidError(true);
        toast.error("Something went wrong");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = () => {
    if (!isOpen) {
      setNotificationsAsRead();
    }
    setIsOpen((prevState) => !prevState);
  };

  const loadMoreNotifications = () => {
    if (!isLoading && !!lastPage.current && page < lastPage.current) {
      setPage((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getNotifications();
    } else {
      if (signalRef.current) {
        signalRef.current.cancel(SIGNAL_CANCEL_MESSAGE);
      }
      setNotifications(null);
      setPage(1);
      setDidError(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [page, isOpen]);

  return (
    <Dropdown
      tag="li"
      className="dropdown-notification nav-item"
      isOpen={isOpen}
      toggle={toggle}
    >
      <DropdownToggle tag="a" className="nav-link nav-link-label">
        <Bell size={21} />
        <Badge
          pill
          color={theme === "Light" ? "primary" : "warning"}
          className="badge-up"
          style={{ width: "max-content", padding: "5px" }}
        >
          {notificationCount}
        </Badge>
      </DropdownToggle>
      <DropdownMenu
        tag="ul"
        right
        className="dropdown-menu-media Notifications"
      >
        <li className="dropdown-menu-header">
          <div className="dropdown-header mt-0">
            <h3 className="text-white">
              {notificationCount === 0 ? "No" : notificationCount}{" "}
              {notificationCount === 1
                ? "New Notification"
                : "New Notifications"}
            </h3>
          </div>
        </li>

        <Virtuoso
          id="virtuoso-scroller"
          className="media-list position-relative"
          data={notifications}
          context={{ didError, isLoading, getNotifications }}
          itemContent={(_, notification) => (
            <Notification
              notification={notification}
              history={history}
              setIsOpen={setIsOpen}
              theme={theme}
            />
          )}
          style={{ height: 400 }}
          endReached={loadMoreNotifications}
          components={{ Footer }}
        />

        <li className="dropdown-menu-footer">
          <DropdownItem
            tag="a"
            className={`p-1 text-center ${
              theme === "dark" ? "DarkHover2" : ""
            }`}
            onClick={() => {
              history.push("/notifications?tab=all");
            }}
          >
            <span className="align-middle">Read all notifications</span>
          </DropdownItem>
        </li>
      </DropdownMenu>
    </Dropdown>
  );
}

const Notification = ({ notification, history, setIsOpen, theme }) => {
  const notificationClickHandler = (notification) => {
    return () => {
      notificationRedirection(notification, history);
      setIsOpen(false);
    };
  };

  return (
    <div
      className="d-flex justify-content-between"
      onClick={notificationClickHandler(notification)}
    >
      <Media className="d-flex align-items-start">
        <Media body>
          <Media
            style={{ textTransform: "capitalize" }}
            heading
            className={
              theme === "light"
                ? "primary media-heading"
                : "warning media-heading"
            }
            tag="h6"
          >
            {bellNotificationTypeToNameMap[notification.notification_type] ??
              notification.notification_type}
          </Media>
          <p className="notification-text">{notification.message}</p>
        </Media>
        <small>{moment(notification.created_at).fromNow()}</small>
      </Media>
    </div>
  );
};

const Error = ({ getNotifications, isLoading }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center py-2 px-1">
      <h5>Something went wrong 🛠️</h5>
      <Button
        onClick={getNotifications}
        disabled={isLoading}
        color="warning"
        size="sm"
      >
        {isLoading ? <Spinner size="sm" /> : "Try again"}
      </Button>
    </div>
  );
};

const Footer = ({ context: { didError, isLoading, getNotifications } }) => {
  if (didError) {
    return <Error isLoading={isLoading} getNotifications={getNotifications} />;
  } else if (isLoading) {
    return (
      <div className="p-2 d-flex justify-content-center">
        <Spinner />
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    notificationCount: state.notifications.notificationCount,
    theme: state.customizer.theme,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationsAsRead: SetNotificationsAsReadAction(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsDropdown);
