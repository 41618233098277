import React, { useEffect, useRef, useState } from "react";
import Avatar from "../views/pages/AvatarComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Spinner } from "reactstrap";
import { IKImage } from "imagekitio-react";
import verificationBadge from "../assets/img/icons/verification-badge.png";

const SuggestionsDropdown = ({
  query,
  users,
  isLoading,
  clickSuggestedUserHandler,
  setUserSuggestions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const clickOutsideRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []); // eslint-disable-line

  useEffect(() => {
    setIsOpen(true);
  }, [query]); // eslint-disable-line

  const handleClickOutside = (event) => {
    if (clickOutsideRef && !clickOutsideRef.current.contains(event.target)) {
      setUserSuggestions([]);
      setIsOpen(false);
    }
  };

  const userClickHandler = (user) => {
    setTimeout(() => setIsOpen(false), 10);
    clickSuggestedUserHandler(user);
  };

  return (
    <div ref={clickOutsideRef}>
      <PerfectScrollbar className="dropdown">
        {isOpen && (
          <ul
            className="pl-0"
            style={{ position: "relative", zIndex: "99999" }}
          >
            {isLoading ? (
              <div className="d-flex justify-content-center p-1">
                <Spinner />
              </div>
            ) : users?.length > 0 ? (
              users?.map((user) => (
                <li
                  key={user.id}
                  className="d-flex"
                  onClick={() => userClickHandler(user)}
                >
                  {user.image ? (
                    // <img src={S3_BASE_URL + user.image} alt="user avatar" />
                    <IKImage
                      path={user.image}
                      transformation={[
                        {
                          width: 60,
                          height: 60,
                        },
                      ]}
                      height="60"
                      width="60"
                    />
                  ) : (
                    <Avatar
                      content={
                        user.nickName?.charAt(0) || user.username?.charAt(0)
                      }
                      className="marg-0 avatar"
                      size="sm"
                    />
                  )}
                  <div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0">{user.nickName || user.username}</p>
                      {(!!user.is_creator ||
                        (!user.is_creator && !!user.is_user_verified)) && (
                        <span>
                          <img
                            src={verificationBadge}
                            alt="verification badge"
                            className="img-xxs ml-2px"
                          />
                        </span>
                      )}
                    </div>
                    <small>@{user.username}</small>
                  </div>
                </li>
              ))
            ) : (
              <li style={{ pointerEvents: "none" }}>No results found</li>
            )}
          </ul>
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default SuggestionsDropdown;
