import {
  fetchUnreadCount,
  updateNotificationCount,
} from "../../../services/notificationService";

export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";
export const INCREASE_NOTIFICATION_COUNT = "INCREASE_NOTIFICATION_COUNT";

export const GetUnreadCountAction = (dispatch, history) => {
  return async () => {
    const isNotificationsPageOpen =
      history.location.pathname.toLowerCase() === "/notifications";

    if (isNotificationsPageOpen) {
      SetNotificationsAsReadAction(dispatch)();
      return;
    }

    try {
      const response = await fetchUnreadCount();

      if (response.data.status) {
        dispatch(SetNotificationCountAction(response.data.count));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const SetNotificationsAsReadAction = (dispatch) => {
  return () => {
    try {
      dispatch(SetNotificationCountAction(0));

      const data = new FormData();
      data.append("user_id", window.localStorage.getItem("user_id"));
      updateNotificationCount(data);
    } catch (err) {
      console.log(err);
    }
  };
};

export const SetNotificationCountAction = (value) => {
  return {
    type: SET_NOTIFICATION_COUNT,
    value,
  };
};

export const IncreaseNotificationCountAction = () => {
  return {
    type: INCREASE_NOTIFICATION_COUNT,
  };
};
