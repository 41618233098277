import axios from "axios";
import { beforeUnloadHandler } from "../utils/beforeUnloadHandler";
import { Mutex } from "async-mutex";

const mutex = new Mutex();

export default class ServiceClient {
  client;

  constructor(config) {
    this.client = axios.create(config);

    this.client.interceptors.request.use(
      async (config) => {
        let accessToken = getAccessToken();

        if (!!accessToken) {
          if (config.headers) {
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
          if (!config.headers) {
            config.headers = {};
            config.headers["Authorization"] = "Bearer " + accessToken;
          }
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          const refreshToken = getRefreshToken();
          const accessToken = getAccessToken();

          if (!!accessToken && !!refreshToken) {
            if (!mutex.isLocked()) {
              const release = await mutex.acquire();

              try {
                const response = await axios.get(
                  process.env.REACT_APP_APIS +
                    `auth/refresh?refreshToken=${encodeURIComponent(
                      refreshToken
                    )}`,
                  {
                    headers: {
                      Authorization: "Bearer " + accessToken,
                    },
                  }
                );
                setTokens({
                  token: response.data.token,
                  refreshToken: response.data.refreshToken,
                });
                release();
                return this.client(error.config);
              } catch {
                unauthorizedHandler();
              }
            } else {
              await mutex.waitForUnlock();
              const config = error.config;

              const accessToken = getAccessToken();

              if (config.headers) {
                config.headers["Authorization"] = "Bearer " + accessToken;
              } else {
                config.headers = {};
                config.headers["Authorization"] = "Bearer " + accessToken;
              }

              return this.client(error.config);
            }
          } else {
            unauthorizedHandler();
          }
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  get(endpoint, config = {}) {
    return this.client.get(endpoint, config);
  }

  post(endpoint, payload, config) {
    return this.client.post(endpoint, payload, config);
  }

  put(endpoint, payload) {
    return this.client.put(endpoint, payload);
  }

  delete(endpoint, payload) {
    return this.client.delete(endpoint, { data: payload });
  }

  patch(endpoint, payload) {
    return this.client.patch(endpoint, payload);
  }
}

const getAccessToken = () => {
  return window.localStorage.getItem("token");
};

const getRefreshToken = () => {
  return window.localStorage.getItem("refresh_token");
};

const setTokens = (payload) => {
  window.localStorage.setItem("refresh_token", payload.refreshToken);
  window.localStorage.setItem("token", payload.token);
};

const unauthorizedHandler = () => {
  window.localStorage.clear();
  window.removeEventListener("beforeunload", beforeUnloadHandler);
  window.location.href = `${process.env.REACT_APP_BASE_URL}?reason=401`;
};
