import themeConfig from "../../configs/themeConfig";
import { messagingInitialState } from "../reducers/messaging/messagingReducer";
import { notificationsInitialState } from "../reducers/notifications/notificationsReducer";
import { liveInitialState } from "../reducers/liveReducer/liveReducer";
import { mediaDeviceInitialState } from "../reducers/mediaDevice/mediaDeviceReducer";
import { modalInitialState } from "../reducers/modal/modalReducer";
import { postsInitialState } from "../reducers/posts/postsReducer";
import { usersInitialState } from "../reducers/users/usersReducer";

export const migrations = {
  0: (state) => {
    return {
      ...state,
      device: undefined,
    };
  },
  1: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        chatUsersIds: null,
        onlineChannels: {},
        newMessageCount: 0,
      },
    };
  },
  2: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        allUsers: [],
        followers: [],
        following: [],
        subscribers: [],
        subscribing: [],
      },
    };
  },
  3: (state) => {
    return {
      ...state,
      customizer: themeConfig,
    };
  },
  4: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        topCreators: [],
        suggestedUsers: [],
      },
    };
  },
  5: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        isFetchingUsers: false,
      },
    };
  },
  6: (state) => {
    return {
      ...state,
      messaging: messagingInitialState,
    };
  },
  7: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        isUserLive: false,
      },
      notifications: notificationsInitialState,
    };
  },
  8: (state) => {
    return {
      ...state,
      live: liveInitialState,
    };
  },
  9: (state) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        notificationCount: 0,
      },
    };
  },
  10: (state) => {
    return {
      ...state,
      live: {
        ...state.live,
        isUserLiveFromThisSession: false,
      },
    };
  },
  11: (state) => {
    return {
      ...state,
      mediaDevice: mediaDeviceInitialState,
    };
  },
  12: (state) => {
    return {
      ...state,
      modal: modalInitialState,
      posts: postsInitialState,
    };
  },
  13: (state) => {
    return {
      ...state,
      app: {
        ...state.app,
        topCreators: null,
        suggestedUsers: null,
      },
      users: usersInitialState,
    };
  },
};
