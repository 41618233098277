import { timelineMap } from "../../../utils/utils";
import {
  POST_CATEGORY,
  USER,
  FROM,
  ALL_CLEAR,
  FLAGGED_KEYWORDS,
  IS_MODERATOR,
  MODERATOR_PERMISSIONS,
  IS_FCM_TOKEN_AVAILABLE,
} from "../../actions/app/appActions";

const initialState = {
  postCat: timelineMap.feed,
  user: null,
  from: "",
  flaggedKeywords: [],
  isModerator: false,
  moderatorPermissions: {},
  isFcmTokenAvailable: false,
};
const app = (state = initialState, action) => {
  switch (action.type) {
    case POST_CATEGORY:
      return {
        ...state,
        postCat: action.value,
      };
    case USER:
      return {
        ...state,
        user: action.value,
      };
    case FROM:
      return {
        ...state,
        from: action.value,
      };
    case FLAGGED_KEYWORDS:
      return {
        ...state,
        flaggedKeywords: action.value,
      };
    case IS_MODERATOR:
      return {
        ...state,
        isModerator: action.value,
      };
    case MODERATOR_PERMISSIONS:
      return {
        ...state,
        moderatorPermissions: action.value,
      };
    case IS_FCM_TOKEN_AVAILABLE:
      return {
        ...state,
        isFcmTokenAvailable: action.value,
      };
    case ALL_CLEAR:
      return initialState;

    default:
      return state;
  }
};
export default app;
